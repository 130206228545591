<template>
  <div class="live-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header"></div>

      <div class="nowPosition">
        <span @click="goIndex" style="cursor: pointer; font-size: 15px"
          >首页</span
        >
        <span style="padding-left: 4px; padding-right: 4px; font-size: 15px"
          >></span
        >
        <span @click="goNews" style="cursor: pointer; font-size: 15px"
          >新闻中心</span
        >
        <span style="padding-left: 4px; padding-right: 4px; font-size: 15px"
          >></span
        >
        <span style="color: #333333; font-weight: 500; font-size: 16px"
          >新闻详情</span
        >
      </div>

      <!-- 新闻详情-->
      <div class="deviceDetail">
        <div class="detail">
          <!-- <p class="name">{{ productInfo.productName }}</p> -->
          <p class="characteristic">{{ productInfo.title }}</p>
          <p v-html="productInfo.content" style="margin-top: 10px"></p>
        </div>
        <div class="imgContainer">
          <img
            :src="productInfo.imageUrl"
            alt=""
            width="200px"
            height="260px"
          />
        </div>
      </div>
    </div>
    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>
  
  <script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getNewsDetail } from "@/api/newWebsite";
export default {
  name: "device-detail",
  components: { headerNav, footerNav },
  metaInfo: {
    title:
      "新巢天诚-新闻中心 智慧通行|人脸识别终端|人行通道闸机|CAT.1智能门锁|LORA智能门锁|UNB联网锁|NB人脸智能门锁|WiFi无线物联网锁|人才公寓管理系统|智慧公寓管理系统",
    meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      productInfo: {},
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        name: "Home",
      });
      sessionStorage.setItem("checkedIndex", 1);
    },
    goNews() {
      this.$router.push({
        name: "press-center",
      });
      sessionStorage.setItem("checkedIndex", 2);
    },

    getNewsDetail(id) {
      getNewsDetail({
        id: id,
      })
        .then((res) => {
          if (res.code == 0) {
            this.productInfo = res.data;
            // console.log("产品详情", this.productInfo);
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获新闻详情失败,请稍后再试");
        });
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    this.getNewsDetail(this.$route.query.id);
  },
};
</script>
  
  <style scoped>
.live-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 40px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/new/press.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.deviceDetail {
  position: relative;
  top: 20px;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 40px;
  display: flex;
}
.detail {
  padding-right: 20px;
  text-align: left;
  width: 90%;
}
.imgContainer {
  flex: 1;
}
.name {
  font-size: 24px;
  font-weight: bold;
  color: #111;
}
.characteristic {
  font-size: 23px;
  color: #cd3333;
  text-align: left;
  margin-top: 10px;
}
.nowPosition {
  max-width: 1200px;
  margin: auto;
  text-align: left;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .nowPosition {
    margin-left: 20px;
  }
  .deviceDetail {
    display: block;
    padding: 20px;
  }
}
</style>